import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export function AcupunctureCard({
  idx,
  name,
  image,
  imageStyle,
  hoverContent,
}: {
  idx: number;
  name: string;
  image: string;
  imageStyle?: React.CSSProperties;
  hoverContent: string;
}) {
  return (
    <StyledCard
      className="card"
      sx={{
        width: 345,
        margin: "24px 0px",
        height: 400,
        backgroundColor: "#F3D3B4",
      }}
      key="name"
    >
      <CardHeader
        title={
          <Typography variant="h6" color="black">
            {name}
          </Typography>
        }
        style={{ backgroundColor: "#F3D3B4" }}
      />
      <CardMedia
        component="img"
        height="194"
        image={image}
        alt={name}
        style={imageStyle}
      />
      <CardContent style={{ backgroundColor: "#F3D3B4", marginTop: 2 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {hoverContent}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}

// const truncate = (text: string) => {
//   return text.length > 220
//     ? [true, text.substring(0, 220) + "..."]
//     : [false, text];
// };

const StyledCard = styled(Card)`
  border: 1px solid grey;
  box-shadow: -3px 6px 5px 0px rgba(176, 164, 176, 1);
  transition: all 0.3s ease-in-out;

  :hover {
    box-shadow: -3px 18px 20px 0px rgba(99, 89, 99, 1);
  }
`;
