import { Typography } from "@mui/material";

import CuppingImage from "../../../assets/cupping.png";
import { useScreenSize } from "../../../hooks.ts/useScreenSize";

export const CuppingDetails = () => {
  const { width } = useScreenSize();

  const isSmallerScreen = width < 600;
  const isMediumScreen = width < 1000;

  const imgWidth = isSmallerScreen ? "inherit" : isMediumScreen ? 300 : "unset";
  return (
    <div
      style={{
        display: "flex",
        flexWrap: isSmallerScreen ? "wrap" : "nowrap",
        width: "100%",
      }}
    >
      <img
        src={CuppingImage}
        style={{
          maxWidth: 450,
          width: imgWidth,
          marginBottom: 24,
        }}
        alt="cupping"
      />
      <div style={{ marginTop: 0, marginLeft: 24, marginRight: 8 }}>
        <Typography sx={{ marginBottom: 2 }} variant="h4">
          What is Cupping?
        </Typography>
        <Typography variant="body1">
          Cupping is the placement of glass /plastic cups in specific areas of
          the body and creating a suction in the cup. The suction created
          improves blood flow to the area and helps with healing and eliminating
          toxins from the body. It is a non-invasive procedure that may be done
          alone as the sole treatment or complementary to acupuncture.
        </Typography>
      </div>
    </div>
  );
};
