export const cardContent = [
  {
    name: "Male & Female Fertility",
    content: "",
    hoverContent:
      "fertility, IUI/ IVF acupuncture, high FSH level, obstruction of Fallopian tube, PCOS, Chronic pelvic inflammation disease(PID), ovulation problem, egg quality improvement, poor implantation, age infertility/ fertility, low sperm count, reduced sperm motility, reduced libido",
    image: "/assets/acupuncture/fertility.png",
  },
  {
    name: "Menopausal Syndrome",
    content: "",
    hoverContent:
      "hot flash, night sweats, irritability, sugar craving. sleep disturbance, decreased libido…",
    image: "/assets/acupuncture/menopause.png",
  },
  {
    name: "Pregnancy Support",
    content: "",
    hoverContent: "back pain, morning sickness, frequent miscarriage…",
    image: "/assets/acupuncture/pregnant.png",
    imageStyle: {
      objectFit: "cover" as any,
      objectPosition: "40% 80%",
    },
  },
  {
    name: "Impotence",
    content: "",
    hoverContent: "back pain, morning sickness, frequent miscarriage…",
    image: "/assets/acupuncture/impotent.png",
  },
  {
    name: "PMS (premenstrual Syndrome)",
    content: "/assets/acupuncture/",
    hoverContent:
      "fatigue, hormone disorder, mood swings, depression, anxiety, poor concentration…",
    image: "/assets/acupuncture/pms.png",
  },
  {
    name: "Pain Management",
    content: "",
    hoverContent:
      "low back pain, migraine, TMJ, headache, sciatica, rheumatoid arthritis, shoulder pain, neck pain, carpal tunnel syndrome, fibromyalgia, pinched nerve, tendonitis, tennis elbow, frozen shoulder, Bell's palsy, osteoarthritis, tremors, trigeminal neuralgia, bone spurs, heel spurs",
    image: "/assets/acupuncture/back-pain.png",
  },
  {
    name: "Sports Injury",
    content: "",
    hoverContent:
      "stress fracture, compression fracture, knee pain, muscle irritation, inflammation, torn ligament, back sprain or strain…",
    image: "/assets/acupuncture/sport-pain.png",
  },
  {
    name: "Hypertension",
    content: "",
    hoverContent:
      "high blood pressure, low blood pressure, stroke, High cholesterol",
    image: "/assets/acupuncture/hypertention.png",
  },
  {
    name: "Allergy",
    content: "",
    hoverContent: "Sneezing, runny nose, nasal congestion, itchy eyes,cough…",
    image: "/assets/acupuncture/allergy.png",
  },
  {
    name: "Stress",
    content: "",
    hoverContent: "Fatigue, insomnia, anxiety, muscle tension…",
    image: "/assets/acupuncture/stress.png",
  },
  {
    name: "Indigestion",
    content: "",
    hoverContent:
      "acid reflux, gallbladder stones, irritable bowel syndromes (IBS), constipation, Crohn's, colitis,bloating, Heartburn (GERD)...",
    image: "/assets/acupuncture/gut.png",
  },
  {
    name: "Quit Smoking",
    content: "",
    hoverContent: "Stop/quit smoking, addiction, stop drinking…",
    image: "/assets/acupuncture/quit-smoking.png",
  },
  {
    name: "Respiratory Conditions",
    content: "",
    hoverContent: "bronchitis, asthma, shortness of breath…",
    image: "/assets/acupuncture/lung.png",
  },
  {
    name: "Immunity Boost",
    content: "",
    hoverContent:
      "fatigue, thyroid disorder, lupus, chronic fatigue syndrome (CFS), blisters, herpes, multiple sclerosis, shingles",
    image: "/assets/acupuncture/immuno.png",
  },
  {
    name: "Bladder Conditions",
    content: "",
    hoverContent: "Urinary incontinence, interstitial cystitis…",
    image: "/assets/acupuncture/bladder.png",
  },
  {
    name: "Weight Loss",
    content: "",
    hoverContent: "",
    image: "/assets/acupuncture/weight-loss.png",
  },
  {
    name: "Cancer Support",
    content: "",
    hoverContent:
      "Cancer is best managed by a team of professionals which includes your oncologist. Acupuncture is very effective in the management of the side effects of chemotherapy, radiation therapy and immunotherapy.",
    image: "/assets/acupuncture/chemo.png",
  },
  {
    name: "Depression",
    content: "",
    hoverContent: "Depression, anxiety, panic attacks…",
    image: "/assets/acupuncture/anxiety.png",
  },
];
