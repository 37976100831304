import { Typography } from "@mui/material";

import FacialImage from "../../../assets/facial.png";
import SkinImage from "../../../assets/skin.png";
import { useScreenSize } from "../../../hooks.ts/useScreenSize";

export const FacialRejuvination = () => {
  const { width } = useScreenSize();

  const isSmallerScreen = width < 600;
  const isMediumScreen = width < 1000;

  const imgWidth = isSmallerScreen ? "inherit" : isMediumScreen ? 300 : "unset";
  return (
    <div
      style={{
        display: "flex",
        flexWrap: isSmallerScreen ? "wrap" : "nowrap",
        width: "100%",
      }}
    >
      <img
        src={FacialImage}
        style={{
          width: imgWidth,
          marginBottom: 24,
          maxHeight: 280,
        }}
        alt="cupping"
      />
      <div
        style={{ marginTop: 0, marginLeft: 24, marginRight: 8, width: "100%" }}
      >
        <Typography sx={{ marginBottom: 2 }} variant="h4">
          What is Facial Rejuvination?
        </Typography>
        <Typography variant="body1">
          The placement of needles at specific points on the face results in
          microtrauma. The body responds by metabolic processes that recruit
          fresh blood and nutrients to the area, but also by generating collagen
          in the areas affected. This allows for the filling out of the lines of
          the face.
        </Typography>
        <Typography sx={{ margin: "24px 0px" }} variant="h5">
          Who can benefit?
        </Typography>
        <Typography sx={{ marginBottom: 2 }} variant="body1">
          Because this technique can also be preventative, it can be introduced
          from in the twenties. It reduces the effect of gravity on the face.
        </Typography>
        <img
          src={SkinImage}
          alt="skin"
          style={{
            width: imgWidth,
            marginBottom: 24,
          }}
        />
      </div>
    </div>
  );
};
