import styled from "@emotion/styled";
import { Box, Button, Menu, MenuItem, Modal, Typography } from "@mui/material";
import { useRef, useState } from "react";

import Logo from "../../assets/bird-nest.png";
import { Contact } from "../../views/contact/Contact";
import { useScreenSize } from "../../hooks.ts/useScreenSize";

const modalStyle = {
  position: "absolute" as "absolute",
  borderRadius: "5px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const mobileModalStyle = {
  width: "100%",
  bgcolor: "background.paper",
  height: "100%",
};

export const Header = ({
  setSelectedPage,
}: {
  setSelectedPage: (page: number) => void;
}) => {
  const menuRef = useRef<null | HTMLButtonElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  const { width } = useScreenSize();

  const isSmallScreen = width < 500;
  const isSmallestScreen = width < 320;

  if (isSmallScreen) {
    return (
      <StyledHeader>
        <div style={{ margin: "auto", padding: 10 }}>
          <img
            height={isSmallScreen ? 50 : 100}
            src={Logo}
            alt="well nest logo"
          />
        </div>
        <div style={{ margin: "auto" }}>
          <Typography variant={isSmallScreen ? "h6" : "h5"}>
            Marlene Cummings, DAOM, MBBS, LAc, MAOM
          </Typography>
        </div>

        <div>
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="outlined"
            size="large"
          >
            Make an Appointment
          </Button>
        </div>
        <NavContainer>
          <Button onClick={() => setSelectedPage(0)} size="large">
            <Typography>Home</Typography>
          </Button>
          <Button
            onClick={() => setSelectedPage(1)}
            size="large"
            color="primary"
          >
            <Typography>Services</Typography>
          </Button>
          {!isSmallestScreen && (
            <Button
              onClick={() => setSelectedPage(4)}
              size="large"
              color="primary"
            >
              <Typography>Contact</Typography>
            </Button>
          )}
          <Button
            ref={menuRef}
            onClick={() => setIsNavMenuOpen(true)}
            size="large"
            color="primary"
          >
            <Typography>More...</Typography>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={menuRef.current}
            open={isNavMenuOpen}
            onClose={() => setIsNavMenuOpen(false)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setSelectedPage(3);
                setIsNavMenuOpen(false);
              }}
            >
              Testimonials
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedPage(2);
                setIsNavMenuOpen(false);
              }}
            >
              FAQs
            </MenuItem>
            {isSmallestScreen && (
              <MenuItem
                onClick={() => {
                  setSelectedPage(4);
                  setIsNavMenuOpen(false);
                }}
              >
                Contact
              </MenuItem>
            )}
          </Menu>
        </NavContainer>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Box sx={mobileModalStyle}>
            <Contact isAppointment onClose={() => setIsModalOpen(false)} />
          </Box>
        </Modal>
      </StyledHeader>
    );
  }
  return (
    <StyledHeader>
      <NameContent>
        <div style={{ marginRight: 60 }}>
          <img
            height={isSmallScreen ? 50 : 100}
            src={Logo}
            alt="well nest logo"
          />
        </div>
        <div style={{ marginRight: 40 }}>
          <Typography variant={isSmallScreen ? "h6" : "h5"}>
            Marlene Cummings, DAOM, MBBS, LAc, MAOM
          </Typography>
        </div>
        <div>
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="outlined"
            size="large"
          >
            Make an Appointment
          </Button>
        </div>
      </NameContent>
      <NavContainer>
        <Button onClick={() => setSelectedPage(0)} size="large">
          <Typography>Home</Typography>
        </Button>
        <Button onClick={() => setSelectedPage(1)} size="large" color="primary">
          <Typography>Services</Typography>
        </Button>
        <Button onClick={() => setSelectedPage(2)} size="large" color="primary">
          <Typography>FAQ</Typography>
        </Button>
        <Button onClick={() => setSelectedPage(3)} size="large" color="primary">
          <Typography>Testimonials</Typography>
        </Button>
        <Button onClick={() => setSelectedPage(4)} size="large" color="primary">
          <Typography>Contact</Typography>
        </Button>
      </NavContainer>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={modalStyle}>
          <Contact isAppointment onClose={() => setIsModalOpen(false)} />
        </Box>
      </Modal>
    </StyledHeader>
  );
};

const NameContent = styled.div`
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavContainer = styled.header`
  width: 100%;
  border-bottom: 1px solid black;
  height: 40px;
`;

const StyledHeader = styled.div`
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #fff1e0;
  z-index: 1001;
`;
